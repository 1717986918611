import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export function OpenFullScreenImage(src) {
  if (src !== null &&
      src !== undefined &&
      src !== "" &&
      src?.length > 0) {
    var event = new CustomEvent("openFullscreenImage", { detail:{ src:src }});
    document.dispatchEvent(event);
  }
}

export default class FullscreenImage extends React.Component {
  
  constructor(props) {
    super(props);
  
    this.state = {
      open: props?.open ?? false,
      src: props?.src ?? ""
    };
  }

  componentDidMount() {
    document.addEventListener("openFullscreenImage", (e) => {
      this.setState({ src: e.detail.src, open: true });
    });
  }
  
  render() {
    return (
      <Dialog
        maxWidth="lg"
        open={this.state.open}
        onClose={() => this.setState({open:false})}>
        {
          this.state?.src?.length > 0 &&
          <img
          src={this.state.src}
          alt="fullscreen asset"
          className="fullscreenImage" />
        }
      </Dialog>
    );
  }
}