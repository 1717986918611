
export function GetSession(client, type) {

	if (document.cookie?.length > 0) {

		const cookies = document.cookie?.split(';');
		let session = "";
		
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].split(`id365_${client}_${type}=`)[1];
			if (cookie) {
				session = JSON.parse(cookie);
				break;
			}
		}

		return session;
	}

	return null;
}
