import React from "react";
import { Route, Switch } from "react-router-dom";
import AssetInfo from "../Pages/Asset/AssetInfo";
import CrewInfo from "../Pages/Crew/Crew";
import Login from "../Pages/Login/Login";
import { GetSession } from "../Services/SessionService";

export default class Routing extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			session: null,
			clientId: window.location.pathname?.split('/')[2],
			accessType: window.location.pathname?.split('/')[1]
		};
	}

	componentDidMount() {
		if (window.location.pathname !== "/login" &&
				window.location.pathname !== "/") {
			// get session
			const sesh = GetSession(this.state.clientId, this.state.accessType);

			if (sesh) {
				this.setState({
					session: sesh
				});
			} else {
				window.location.replace(`/login?returnUrl=${window.location.pathname}`);
			}

		}
	}
	
	render() {
		return (
			<Switch>
				<Route path={"/"} exact children={() => ""} />
				<Route path={"/asset/:clientId/:supplierMachineConfigId"} children={<AssetInfo />} />
				<Route path={"/crew/:clientId/:supplierContactId"} children={<CrewInfo />} />
				<Route path={"/login"} children={<Login />} />
			</Switch>
		);
	}

}

