import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import ComplianceRow from './ComplianceRow';
import StyledTableRow from '../Table/StyledTableRow';
import StyledTableCell from '../Table/StyledTableCell';

export default function ComplianceTable(props) {
	return (
		<div className="m-gutter">
			<Typography variant="caption" color="textSecondary"><InfoOutlinedIcon fontSize="inherit" /> &nbsp; Tap on a row to expand its documents</Typography>
			<Box mb={4}>
				<TableContainer component={Card}>
					<Table aria-label="customized table">
						<TableHead>
							<StyledTableRow>
								{props?.headers?.map((header, index) => {
									return <StyledTableCell key={`heading_${index}`} component="th" align="justify">{header.name}</StyledTableCell>
								})}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{props?.rows?.map((row, i) => (
								<ComplianceRow row={row} key={i} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</div>
	);
}
