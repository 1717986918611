import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { deepOrange, blue, grey } from '@material-ui/core/colors';

const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

let theme = null;

if (darkMode) {
  theme = createTheme({
    palette: {
      primary: deepOrange,
      secondary: grey,
      type: "dark"
    }
  });
  document.body.style.background = grey[900];
}
else
  theme = createTheme({
    palette: {
      primary: deepOrange,
      secondary: blue,
      type: "light"
    }
  })

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
