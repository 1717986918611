import React from "react";

export default class Logo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			logo: null
		}
	}

	getLogo = () => {
		let returnUrl = window.location.search?.split("returnUrl=")[1];

		let clientId = returnUrl?.split('/')[2] ?? window.location.pathname.split('/')[2];

		if (clientId?.length > 0 && this.state.logo == null) {

			fetch(`${process.env.REACT_APP_API}/id365/logo?client=${clientId}`)
			.then((res) => res.text())
			.then((logo) => {
				if (logo !== "" && logo !== null) {

					this.setState({
						logo:logo
					});

					document.dispatchEvent(new Event("logoLoaded"));
				} else {
					window.location.replace("/");
				}
			});

		}

	};

	componentDidMount() {
		this.getLogo();
	}

	render() {
		return this.state.logo != null ? <img src={this.state.logo} className="logo" alt="logo" /> : "";
	}
}

