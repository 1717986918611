import React from "react";

// Material
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from '@material-ui/core/Fab';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import PreStartTable from "../../Components/PreStart/PreStartTable";
import ComplianceTable from "../../Components/Compliance/ComplianceTable";
import ToastEvent from '../../Components/Toast/ToastEvent';
import { GetSession } from "../../Services/SessionService";
import { OpenFullScreenImage } from "../../Components/Image/FullscreenImage";
import { withRouter } from "react-router-dom";


class AssetInfo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			tab:0,
			loading: false,
			session: GetSession(props?.match?.params?.clientId, "asset"),
			clientId: props?.match?.params?.clientId,
			supplierMachineConfigId: props?.match?.params?.supplierMachineConfigId
		};

	}

	get = async () => {

		if (this.state.loading !== true) {

			this.setState({loading:true});

			var url = `${process.env.REACT_APP_API}/id365/asset?` +
								`clientId=${this.state?.clientId}` +
								`&accessCode=${this.state.session.c}` +
								`&email=${this.state.session.e}` +
								`&supplierMachineConfigId=${this.state?.supplierMachineConfigId}`;

			await fetch(url)
			.then(res => res.body !== null ? res.json() : res.text())
			.then(res => {
				if (res?.isValid === true) {
					this.setState({model: res?.model, loading: false});
				} else {
					ToastEvent("Asset not found!", "error");
				}
			});

		}
	};

	componentDidMount = async () => {
		if (this.state.session) {
			await this.get();
		} else {
			window.location.replace(`/login?returnUrl=${window.location.pathname}`)
		}
	};

	render = () => {
		return (
			<React.Fragment>
				{/* Tabs */}
				<Container maxWidth="sm">
					<Card style={{ textAlign: "center" }}>
						<Tabs
							value={this.state.tab}
							indicatorColor="primary"
							textColor="primary"
							centered
							variant="fullWidth"
							onChange={(event, newValue) => this.setState({ tab: newValue })}>
								<Tab label="Asset Info" icon={<InfoOutlinedIcon fontSize="small" />} />
								<Tab label="Compliance" icon={<DoneAllIcon fontSize="small" />} />
								<Tab label="Pre Start" icon={<AssignmentIcon fontSize="small" />} />
						</Tabs>
					</Card>
				</Container>

				<div className="clear"></div>
				<br />

				{/* Asset Info Card */}
				{ this.state?.loading !== true && 
					<Container maxWidth="sm" style={this.state.tab !== 0 ? { display:"none" } : { display:"block" }}>
						<Card>
							<CardMedia
								width="100%"
								height="200"
								image={this.state?.model?.assetPhoto ?? "/assets/img/no-img.jpg"}
								title="Asset Photo"
								style={{position:'relative',textAlign:"right"}}>
								<Box p={1} position="absolute" right={0}>
								{this.state?.model?.assetPhoto?.length > 0 &&
									<Tooltip placement="left" title="View Fullscreen">
										<Fab size="small" aria-label="add" className="hoverBtn" onClick={() => OpenFullScreenImage(this.state?.model?.assetPhoto)}>
											<AspectRatioIcon />
										</Fab>
									</Tooltip>
								}
								</Box>
								<Box pt={14} pb={1} pl={3} textAlign="left">
									<Typography variant="h5" style={{color:"#fff",textShadow:"1px 1px 6px #000"}}>
										<strong>{this.state?.model?.make} - {this.state?.model?.model}</strong>
									</Typography>
								</Box>
							</CardMedia>
							<Box p={2}>
								<Grid container>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Asset ID</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.assetId}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Asset Type</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.assetType}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Make</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.make}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Model</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.model}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1}>
											<Typography variant="body2" color="textSecondary"><strong>Rego</strong></Typography>
											<Typography variant="body1">
												<strong>
													{
														this.state?.model?.rego?.length > 0 ?
														this.state?.model?.rego
														:
														"N/A"
													}
												</strong>
												</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1}>
											<Typography variant="body2" color="textSecondary"><strong>Rego Expiry</strong></Typography>
											<Typography variant="body1">
												<strong>
													{
													this.state?.model?.regoExpiry ?
													new Date(Date.parse(this.state?.model?.regoExpiry)).toDateString() :
													"N/A"
													}
												</strong>
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Card>
					</Container>
				}

				{/* Compliance Table */}
				<Container maxWidth="md" style={this.state.tab !== 1 ? { display:"none" } : { display:"block" }} disableGutters>
					{ this.state?.loading !== true &&
						this.state?.model?.portfolio?.length > 0 ?
						<ComplianceTable headers={[
							{ name: "Type Name" },
							{ name: "Identifier" },
							{ name: "Obtained" },
							{ name: "Expiry" }
						]} rows={this.state?.model?.portfolio} />
						:
						<Typography align="center" variant="body2" color="textSecondary">Nothing to see here :(</Typography>
					}
				</Container>

				{/* Pre Start Table */}
				<Container maxWidth="md" style={this.state.tab !== 2 ? { display:"none" } : { display:"block" }} disableGutters>
					{ this.state?.loading !== true &&
						this.state?.model?.preStart?.length > 0 ?
						<PreStartTable headers={[
							{ name: "Contact Name" },
							{ name: "Date Completed" },
							{ name: "Warnings" },
							{ name: "Criticals" }
						]} rows={this.state?.model?.preStart} />
						:
						<Typography align="center" variant="body2" color="textSecondary">Nothing to see here :(</Typography>
					}
				</Container>

				{ this.state?.loading === true &&
					<div style={{ textAlign:"center" }}>
						<br />
						<CircularProgress />
					</div>
				}

			</React.Fragment>
		);
	}

}

export default withRouter(AssetInfo);