import React from "react";

// Material
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Link from "@material-ui/core/Link";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CircularProgress from "@material-ui/core/CircularProgress";

import ToastEvent from '../../Components/Toast/ToastEvent';
import { GetSession } from "../../Services/SessionService";
import CustomTable from "../../Components/Compliance/ComplianceTable";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class CrewInfo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			value:0,
			loading: false,
			session: GetSession(props?.match?.params?.clientId, "crew"),
			clientId: props?.match?.params?.clientId,
			supplierContactId: props?.match?.params?.supplierContactId
		};
	}

	get = () => {

		if (this.state.loading !== true) {

			this.setState({loading:true});

			var url = `${process.env.REACT_APP_API}/id365/crew?` +
								`clientId=${this.state?.clientId}` +
								`&accessCode=${this.state.session.c}` +
								`&email=${this.state.session.e}` +
								`&supplierContactId=${this.state?.supplierContactId}`;

			fetch(url)
			.then(res => res.body !== null ? res.json() : res.text())
			.then(res => {
				if (res?.isValid === true) {
					this.setState({model: res?.model, loading: false});
				} else {
					ToastEvent("Crew not found!", "error");
				}
			});
		}
	};

	componentDidMount = () => {
		if (this.state.session !== null) {
			this.get();
		} else {
			window.location.replace(`/login?returnUrl=${window.location.pathname}`)
		}
	};

	render() {
		return (
			<React.Fragment>
				{/* Tabs */}
				<Container maxWidth="sm">
					<Card style={{ textAlign: "center" }}>
						<Tabs
							value={this.state?.value}
							indicatorColor="primary"
							textColor="primary"
							centered
							variant="fullWidth"
							onChange={(event, newValue) => this.setState({ value: newValue })}>
								<Tab label="Crew Info" icon={<InfoOutlinedIcon fontSize="small" />} />
								<Tab label="Compliance" icon={<DoneAllIcon fontSize="small" />} />
						</Tabs>
					</Card>
				</Container>

				<div className="clear"></div>
				<br />
				
				{/* Crew Info Card */}
				{ this.state?.loading !== true && 
					<Container maxWidth="sm" style={this.state.value !== 0 ? { display:"none" } : { display:"block" }}>
						<Card>
							<CardMedia
								width="100%"
								height="200"
								image={this.state?.model?.crewPhoto ?? "/assets/img/no-img.jpg"}
								title="Crew Photo"
								style={{position:'relative',textAlign:"right"}}>
								<Box pt={14} pb={1} pl={3} textAlign="left">
									<Typography variant="h5" style={{color:"#fff",textShadow:"1px 1px 6px #000"}}>
										<strong>{this.state?.model?.cFirstName} {this.state?.model?.cLastName}</strong>
									</Typography>
								</Box>
							</CardMedia>
							<Box p={2}>
								<Grid container>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Given Name</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.cFirstName}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Family Name</strong></Typography>
											<Typography variant="body1"><strong>{this.state?.model?.cLastName}</strong></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Email Address</strong></Typography>
											<Typography variant="body1"><Link href={"mailto:" + this.state?.model?.contactEmail}><strong>{this.state?.model?.contactEmail}</strong></Link></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Phone Number</strong></Typography>
											<Typography variant="body1"><Link href={"tel:" + this.state?.model?.contactNumber}><strong>{this.state?.model?.contactNumber}</strong></Link></Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Industry Start Date</strong></Typography>
											<Typography variant="body1">
												<strong>
													{
														this.state?.model?.indStartDate?.length > 0 ?
														new Date(Date.parse(this.state?.model?.indStartDate)).toDateString()
														:
														"N/A"
													}
												</strong>
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box p={1} mb={1}>
											<Typography variant="body2" color="textSecondary"><strong>Contract Start Date</strong></Typography>
											<Typography variant="body1">
												<strong>
													{
														this.state?.model?.conStartDate?.length > 0 ?
														new Date(Date.parse(this.state?.model?.conStartDate)).toDateString()
														:
														"N/A"
													}
												</strong>
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Card>
					</Container>
				}
				
				{/* Compliance Table */}
				<Container maxWidth="md" style={this.state.value !== 1 ? { display:"none" } : { display:"block" }} disableGutters>
					{
						this.state?.loading !== true &&
						this.state?.model?.portfolio?.length > 0 ?
							<CustomTable headers={[
								{ name: "Type Name" },
								{ name: "Identifier" },
								{ name: "Obtained" },
								{ name: "Expiry" }
							]} rows={this.state?.model?.portfolio} />
							:
							<Typography align="center" variant="body2" color="textSecondary">Nothing to see here :(</Typography>
					}
				</Container>
				{
					this.state?.loading === true &&
					<div style={{ textAlign:"center" }}>
						<br />
						<CircularProgress />
					</div>

				}

			</React.Fragment>
		);
	}

}

export default withRouter(CrewInfo);