import React from 'react';

// Mat
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Components
import StyledTableCell from '../Table/StyledTableCell';
import StyledTableRow from '../Table/StyledTableRow';
import Warning from '@material-ui/icons/Warning';
import Report from '@material-ui/icons/Report';

export default function PreStartRow(props) {
	const { row } = props;
  const [open, setOpen] = React.useState(false);
	return (
		<React.Fragment>
			<StyledTableRow onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
				<StyledTableCell>{row?.contactName}</StyledTableCell>
				<StyledTableCell>{row?.dateAdded}</StyledTableCell>
				<StyledTableCell>{row?.hasWarning ? <Warning /> : "None" }</StyledTableCell>
				<StyledTableCell>{row?.hasCritical ? <Report /> : "None"}</StyledTableCell>
			</StyledTableRow>
			<TableRow>
				<StyledTableCell style={{padding:"0",borderBottom:"0"}} colSpan={4}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box p={2}>
							<Typography variant="overline" color="textSecondary" display="block"><strong>Answers</strong></Typography>
							{
								row?.response?.answers?.length > 0 ?
								<React.Fragment>
									<Grid container>
										{
											row?.response?.answers?.map((f, i) => {
												return (
													<Grid item xs={12} sm={6} key={i}>
														<Box p={1}>
															<Typography variant="body1"><strong>{ f.question }:</strong></Typography>

															<Typography variant="body2">{ !f.message ? (f.response === 1 ? "Yes" : "No") : f.message }</Typography>
																	
														</Box>
													</Grid>
												)
											})
										}
									</Grid>
									<Typography variant="overline" color="textSecondary" style={{ display:"block" }}><strong>Signature</strong></Typography>
									<svg viewBox="0 0 400 200" width="400" height="200" style={{
										background:`url(${"data:image/png;base64," + row?.response?.signature}) center / contain no-repeat`,
										display: "block",
										maxWidth: "100%"
									}}></svg>
								</React.Fragment>
								:
								<Typography align="center" variant="body2" color="textSecondary">nothing to see here :(</Typography>
							}
						</Box>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</React.Fragment>
	);
}
