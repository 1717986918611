
import TableCell from '@material-ui/core/TableCell';
import {withStyles} from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		paddingTop:10,
		paddingBottom:10
	},
	body: {
		fontSize: 14
	}
}))(TableCell);

export default StyledTableCell;
