
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Slide } from "@material-ui/core";


export default class Toast extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			toasts: []
		};
	}

	config = {
		toasts: [],
		autoHideDuration: 4000
	}

	componentDidMount() {
		document.addEventListener("toast", (e) => {
	
      let message = e.detail?.message;
      let severity = e.detail?.severity;

			if (message?.length > 0 && severity?.length > 0) {
				this.addToast(message, severity);
			}
    });
	}

	addToast = (message, severity) => {
		var toast = {
			open: true,
			severity: severity,
			message: message,
			onClose: (index) => {
				this.config.toasts.splice(index, 1);
				this.setState({ toasts: this.config.toasts });
			}
		};

		this.config.toasts.push(toast);
		this.setState({ toasts: this.config.toasts });

	};

	render() {
		return (
			this.state.toasts.map((toast, index) => {
				return (
					<Snackbar
						key={index}
						autoHideDuration={this.config.autoHideDuration}
						open={toast.open}
						TransitionComponent={Slide}
						onClose={toast.onClose}
						anchorOrigin={{vertical:'top',horizontal:'right'}}>
							<Alert
							variant="filled"
							severity={toast.severity}
							onClose={toast.onClose}>{toast.message}</Alert>
					</Snackbar>
				)
			})
		);
	}
}

