import React from 'react';

// Mat
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Grid from '@material-ui/core/Grid';

// Components
import StyledTableCell from '../Table/StyledTableCell';
import StyledTableRow from '../Table/StyledTableRow';
import { OpenFullscreenDocument } from '../Document/FullscreenDocument';

export default function ComplianceRow(props) {
	const { row } = props;
  const [open, setOpen] = React.useState(false);
	return (
		<React.Fragment>
			<StyledTableRow onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
				<StyledTableCell>{row?.typeName ?? row?.portfolioTypeName}</StyledTableCell>
				<StyledTableCell>{row?.identifier}</StyledTableCell>
				<StyledTableCell>{row?.obtained?.length > 0 ? new Date(Date.parse(row?.obtained)).toDateString() : "N/A"}</StyledTableCell>
				<StyledTableCell>{row?.expiryDate?.length > 0 ? new Date(Date.parse(row?.expiryDate)).toDateString() : "N/A"}</StyledTableCell>
			</StyledTableRow>
			<TableRow>
				<StyledTableCell style={{padding:"0",borderBottom:"0"}} colSpan={4}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box p={2}>
							<Typography variant="overline" color="textSecondary" display="block"><strong>Documents</strong></Typography>
							{
								row?.documents?.length > 0 ?
								<Grid container>
								{
									row?.documents?.map((f, i) => {
										return (
											<Grid item xs={12} sm={6} key={i}>
												<Box p={1}>
													<Paper style={{cursor:"pointer"}} onClick={() => OpenFullscreenDocument(f.url)} variant="outlined">
														<Box p={2}>
															<Typography variant="body2" color="primary">
																<AttachFileIcon /> &nbsp; { f.name }
															</Typography>
														</Box>
													</Paper>
												</Box>
											</Grid>
										)
									})
								}
								</Grid>
								:
								<Typography align="center" variant="body2" color="textSecondary">nothing to see here :(</Typography>
							}
						</Box>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</React.Fragment>
	);
}
