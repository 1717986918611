// Reacc
import React from 'react';

// Material
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToastEvent from '../../Components/Toast/ToastEvent';
import { GetSession } from '../../Services/SessionService';

export default class Login extends React.Component {

	constructor(props) {
		super(props);
		
		// configure state
		this.state = {
			accessGranted: false,
			accessCodeSent: false,
			returnUrl: window.location.search?.split('returnUrl=')[1],
			clientId: window.location.search?.split('=')[1]?.split('/')[2],
			accessCodeType: window.location.search?.split('=')[1]?.split('/')[1],
			cookieName: ""
		};
	}

	componentDidMount = () => {

		if (!this.state?.returnUrl?.length > 0) {
			window.location.replace("/");
		}

		let session = GetSession(this.state.clientId, this.state.accessCodeType);
		if (session) {
			this.verifyAccessCode(null, session.e, session.c)
		}

		this.setState({ cookieName:`id365_${this.state.clientId}_${this.state.accessCodeType}=` });
	}

	getAccessCode = (e) => {
		e.preventDefault();

		if (!this.state.submitting) {

			this.setState({submitting:true});

			fetch(`${process.env.REACT_APP_API}/id365/request`, {
				method: 'POST',
				headers: new Headers({'content-type': 'application/json'}),
				body: JSON.stringify({
					clientId: this.state.clientId,
					fullName: e.target.fullName.value,
					emailAddress: e.target.emailAddress.value,
					companyName: e.target.companyName.value,
					accessCodeType: this.state.accessCodeType
				})
			})
			.then(res => res.text())
			.then(res => {
				var success = res === 'true';
				if (success) { 
					this.setState({ accessCodeSent: true, codeEmail: e.target.emailAddress.value });
					ToastEvent("Access code has been sent to your email!", "success");
				} else {
					ToastEvent("Your name or email is invalid!", "error");
				}
			})
			.catch(res => {
				ToastEvent("Your name or email is invalid!", "error");
			})
			.finally(() => {
				this.setState({ submitting: false });
			})
		}


	}

	verifyAccessCode = (e, email, code) => {
		e?.preventDefault();
		if (!this.state.submitting) {

			this.setState({submitting:true});

			const verifyEmail = email !== undefined ? email : e?.target?.verifyEmail?.value;
			const verifyCode = code !== undefined ? code : e?.target?.verifyCode?.value;

			var url = `${process.env.REACT_APP_API}/id365/verify?` +
								`clientId=${this.state.clientId}` +
								`&verifyCode=${verifyCode}` +
								`&verifyEmail=${verifyEmail}` +
								`&accessType=${this.state.accessCodeType}`;

			fetch(url)
			.then(res => res.text())
			.then(res => {
				var success = res === 'true';
				if (success) {

					if (!document.cookie?.indexOf(this.state.cookieName) > -1) {
						document.cookie = this.state.cookieName +
						JSON.stringify({e:verifyEmail, c:verifyCode}) +
						`;expires=${new Date(new Date().setDate(new Date().getDate() + 1)).toUTCString()}`;
					}

					ToastEvent("Access code verified!", "success");

					if (this.state.returnUrl?.length > 0)
						window.location.replace(this.state.returnUrl);

				} else {
					if (document?.cookie?.indexOf(this.state.cookieName) > -1) {
						document.cookie = this.state.cookieName + `;expires=${new Date()}`;
					}
					ToastEvent("Access code or email invalid!", "error");
					this.setState({ submitting: false });
				}
			})
			.catch(res => {
				ToastEvent("Access code or email invalid!", "error");
				this.setState({ submitting: false });
			});
		}
	}
	
	render() {

		return (
			<Container maxWidth="sm">
				<Typography variant="body2" align="center" color="textSecondary"><InfoOutlinedIcon fontSize="inherit" /> &nbsp; complete your details to access the requested info</Typography>
				<br />
				<Card>
					<Box p={4}>
						<form onSubmit={this.state.accessCodeSent === true ? this.verifyAccessCode : this.getAccessCode}>
							<Grid container spacing={4}>
								{	this.state.accessCodeSent !== true &&
									<React.Fragment>
										<Grid item xs={12} sm={6}>
											<TextField variant="outlined" size="small" label="Your Name" name="fullName" fullWidth required autoFocus disabled={this.state?.submitting} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField variant="outlined" size="small" label="Company Name" name="companyName" fullWidth disabled={this.state?.submitting} />
										</Grid>
										<Grid item xs={12}>
											<TextField variant="outlined" size="small" label="Email Address" name="emailAddress" type="email" fullWidth required disabled={this.state?.submitting} />
										</Grid>
									</React.Fragment>
								}
								{	this.state.accessCodeSent === true &&
									<React.Fragment>
										<Grid item xs={12} sm={6}>
											<TextField variant="outlined" size="small" label="Access Code" name="verifyCode" fullWidth autoFocus required disabled={this.state?.submitting} />
										</Grid>
										<Grid item xs={12}>
											<TextField variant="outlined" size="small" label="Email Address" name="verifyEmail" type="email" value={this.state.codeEmail} fullWidth required disabled={this.state?.submitting} />
										</Grid>
									</React.Fragment>
								}
								<Grid item xs={12}>
									<Button type="submit" variant="contained" fullWidth color="primary" size="large" startIcon={this.state?.submitting !== true ? this.state.accessCodeSent !== true ? <LockOutlinedIcon /> : <VerifiedUserIcon /> : null} disabled={this.state.submitting}>
									{
										this.state?.submitting !== true ?
											this.state.accessCodeSent !== true ?
											"Get Access Code"
											:
											"Verify Code"
										:
										<CircularProgress size={26} />
									}
									</Button>
									
									<Typography display="block" align="center">
										<br />
										{ this.state.accessCodeSent === true ?
											<Link variant="button" onClick={()=>this.setState({accessCodeSent:false})} style={{cursor:'pointer'}}>Need an access code?</Link>
											:
											<Link variant="button" onClick={()=>this.setState({accessCodeSent:true})} style={{cursor:'pointer'}}>I already have a code</Link>
										}
									</Typography>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Card>
				<br />
			</Container>
		);

	}

}