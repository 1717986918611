import React from "react";

// Resources
import { BrowserRouter } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Logo from "./Components/Logo/Logo";
import Routing from "./Components/Routing";
import Toast from "./Components/Toast/Toast";
import FullscreenImage from "./Components/Image/FullscreenImage";
import FullscreenDocument from "./Components/Document/FullscreenDocument";

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    // add event listener
    document.addEventListener("logoLoaded", () => {
      this.setState({loading:false});
    });
  }

  render() {
    return (
      <React.Fragment>
        <Logo />
        { // show routed components once loading is complete
          this.state.loading === true &&
          window.location.pathname !== "/" ?
          <div style={{textAlign:"center"}}>
            <CircularProgress style={{ margin: "60px auto" }} />
          </div>
          :
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
        }
        
        <Toast />

        <FullscreenImage />
        <FullscreenDocument />
      </React.Fragment>
    )
  }

}
