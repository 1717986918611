
import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export function OpenFullscreenDocument(src) {
  if (src !== null &&
      src !== undefined &&
      src !== "" &&
      src?.length > 0) {
    var event = new CustomEvent("openFullscreenDocument", { detail:{ src:src }});
    document.dispatchEvent(event);
  }
}

export default class FullscreenDocument extends React.Component {
  
  constructor(props) {
    super(props);
  
    this.state = {
      open: props?.open ?? false,
      src: props?.src ?? ""
    };

    document.addEventListener("openFullscreenDocument", (e) => {
      this.setState({ src: e.detail.src, open: true });
    });
  }

  componentDidMount() {
  }
  
  render() {
    return (
			
      <Dialog
        maxWidth="lg"
				fullWidth
        open={this.state.open}
        onClose={() => this.setState({open:false})}>
        {
          this.state?.src?.length > 0 &&
          <iframe
					allowFullScreen
          src={this.state.src.replace("http://", "https://")}
          alt="fullscreen document"
					style={{ width: "100%", height:"90vh" }}
					title="Document Viewer"></iframe>
        }
      </Dialog>
    );
  }
}